import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import 'core-js/es'
import 'es6-promise/auto'
import { initFirebaseRemoteConfig } from 'firebase-utils'

initFirebaseRemoteConfig()

ReactDOM.render(
    <App/>,
    document.getElementById('app-container')
)
