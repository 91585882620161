import React from 'react'
import Header from './Header'
import Steps from './steps/Steps'
import { Container } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            padding: '0px',
            paddingBottom: '100px' // Creates a space for the chat and recaptcha widgets (also: on Mac OS leaves room for the system dock should it overlap the browser window)
        },
        content: {
            padding: '0px 30px',
            [theme.breakpoints.up('md')]: {
                padding: '0px 40px'
            },
            [theme.breakpoints.up('lg')]: {
                padding: '0px 50px'
            }
        },
        signUpTitle: {
            color: '#197f9f',
            fontSize: '16px',
            textAlign: 'left'
        }
    })
)

interface PageLayoutProps {
    currentStepId: string
}

/**
 * Controls the overal page structure, for all pages in the signup app
 */
const PageLayout = ({ currentStepId } : PageLayoutProps) => {
    const classes = useStyles()

    return (
        <Container className={classes.container}>
            <Header />
            <section className={classes.content}>
                <Steps currentStepId={currentStepId} />
            </section>
        </Container>
    )
}

export default PageLayout
