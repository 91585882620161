import React, { useContext } from 'react'
import TextField from '../../design-components/TextField'
import { Grid } from '@material-ui/core'
import { AsYouType } from 'libphonenumber-js'
import Typography from 'typography'
import Heading from '../../Heading'
import { createStyles, makeStyles } from '@material-ui/core'
import LeadContext from '../../LeadContext'
import Password from './Password'

const useStyles = makeStyles(theme =>
    createStyles({
        requiredFieldsText: {
            color: `${theme.palette.info.dark} !important`,
            marginBottom: '15px'
        },
        footerText: {
            color: `${theme.palette.text.secondary} !important`,
            marginTop: '10px'
        },
        select: {
            height: '44px'
        },
        lockIcon: {
            width: '18px',
            height: '18px',
            marginBottom: '4px'
        },
        zipHelperText: {
            marginTop: '7px'
        }
    })
)

const fieldLabels = {
    company_name: 'Company Name', // eslint-disable-line @typescript-eslint/naming-convention
    first_name: 'First name', // eslint-disable-line @typescript-eslint/naming-convention
    last_name: 'Last Name', // eslint-disable-line @typescript-eslint/naming-convention
    personal_phone_number: 'Mobile number', // eslint-disable-line @typescript-eslint/naming-convention
    password: 'Password',
    email: 'Email'
}

/**
 * Renders inputs/fields collecting information about the user and their company.
 */
const BusinessInfoForm = (): JSX.Element => {
    const classes = useStyles()

    const { leadParams, setLeadParams, savingLead } = useContext(LeadContext)

    const disabled = savingLead

    const renderField = (
        field: string,
        fieldType: string,
        { onChange, InputProps }: { onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined } = {}
    ) : JSX.Element => {
        const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
                onChange(event)
            } else {
                setLeadParams({ ...leadParams, [field]: event.target.value })
            }
        }

        return (
            <TextField
                data-testid={field}
                name={field}
                type={fieldType}
                label={fieldLabels[field]}
                InputProps={{
                    name: field, // may serve as a hint to browser auto-fill logic as to what the field does
                    ...(InputProps || {})
                }}
                value={leadParams[field] || ''}
                onChange={onFieldChange}
                disabled={disabled}
                fullWidth/>
        )
    }

    return (
        <>
            <Heading>
                Business information
            </Heading>

            <Typography variant="helperText" align="left" display="block" className={classes.requiredFieldsText}>
                All fields are required
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {renderField('company_name', 'text')}
                </Grid>

                <Grid item xs={12} md={6}>
                    {renderField('first_name', 'text')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {renderField('last_name', 'text')}
                </Grid>

                <Grid item xs={12}>
                    {renderField('email', 'email')}
                </Grid>

                <Grid item xs={12} md={6}>
                    {renderField('personal_phone_number', 'tel', event => {
                        // Format the number as the user types, except when the user is removing/backspacing characters (prevents bugs/glitches due to formatting re-introducing things like brackets)
                        const inputValue = event.target.value.slice(0, 15)
                        const formattedNumber = inputValue.length >= (leadParams.personal_phone_number?.length || 0) ? new AsYouType('US').input(inputValue) : inputValue
                        setLeadParams({ ...leadParams, personal_phone_number: formattedNumber.slice(0, 15) }) // eslint-disable-line @typescript-eslint/naming-convention
                    })}

                    <Typography variant="helperText" display="block" align="left" className={classes.footerText}>
                        Used for verification purposes
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Password
                        password={leadParams.password || ''}
                        onChange={(val: string) => setLeadParams({ ...leadParams, password: val })}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default BusinessInfoForm
