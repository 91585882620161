import React, { useState } from 'react'
import PasswordRequirements from './PasswordRequirements'
import { HideIcon, ShowIcon } from 'svg-icons'
import TextField from '../../design-components/TextField'
import { createStyles, makeStyles } from '@material-ui/core'

interface PasswordProps {
    password: string;
    onChange: (newPassword: string) => void;
    disabled: boolean;
}

const useStyles = makeStyles(theme =>
    createStyles({
        icon: {
            cursor: 'pointer',
            marginRight: '0.5rem',
            color: ({ visible }: { visible: boolean }) => `${visible ? theme.palette.secondary.main : theme.palette.secondary.dark} !important`
        }
    })
)

/**
 *
 */
const Password = ({ password, onChange, disabled }: PasswordProps): JSX.Element => {
    const [visible, setVisible] = useState<boolean>(false)

    const classes = useStyles({ visible })

    const IconClass = visible ? HideIcon : ShowIcon
    const iconElement = (
        <IconClass
            className={classes.icon}
            onClick={() => setVisible(!visible)}
        />
    )

    return (
        <>
            <TextField
                data-testid='password'
                name='visible'
                type={visible ? 'text' : 'password'}
                label='Password'
                InputProps={{
                    name: 'password',
                    endAdornment: iconElement
                }}
                value={password}
                onChange={(event: React.FormEvent<HTMLInputElement>) => onChange(event.currentTarget.value)}
                disabled={disabled}
                fullWidth
            />

            <PasswordRequirements password={password} />
        </>
    )
}

export default Password
