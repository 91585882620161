import React, { useState, useEffect, useRef } from 'react'
import * as API from './util/API'
import Spinner from 'spinner'
import Alert, { Color as AlertColor } from './design-components/Alert'
import { CartItem, LeadParams } from './types'
import { createStyles, makeStyles } from '@material-ui/core'
import LeadContext from './LeadContext'
import * as GTM from './util/GTM'
import { removeCookie, setCookie } from './util/Cookies'
import { isEmailValid, validateLeadParams } from './util/Validations'
import { formatPhoneNumber } from 'phone-numbers'
import { debounce } from 'debounce'
import countries from 'countries'

const useStyles = makeStyles(() =>
    createStyles({
        spinnerWrapper: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
    })
)

interface LeadProviderProps {
    children: JSX.Element
}

interface MFASession {
    mfa_app_url: string; // eslint-disable-line @typescript-eslint/naming-convention
}

/**
 * Inits the pdc_lead_session_token, which is required by many app.phone.com APIs
 */
const LeadProvider = ({ children } : LeadProviderProps) : JSX.Element => {
    const classes = useStyles()

    const [loadingError, setLoadingError] = useState<Error | null>(null)

    const [leadParams, setLeadParams] = useState<null | LeadParams>(null)

    const [savingLead, setSavingLead] = useState<boolean>(false)

    const [checkingZip, setCheckingZip] = useState<boolean>(false)
    const [isZipValid, setIsZipValid] = useState<boolean>(true)
    const [validatedZipCode, setValidatedZipCode] = useState<string>('')

    const mfaSession = useRef<null | MFASession>(null)
    const skipMFA = useRef<boolean>(false)
    const [redirectingToMFA, setRedirectingToMFA] = useState<boolean>(false)

    const initLead = () => {
        API.initLead().then((initialLeadParams: LeadParams | null) => {
            setLoadingError(null)

            // LeadParams may be null in the event that initLead() is redirecting you to the pricing page, country not supported, etc.
            if (initialLeadParams) {
                if (initialLeadParams.package !== 'voip_user_tiered') {
                    // User originated from classic signup
                    setCookie('is_classic_signup', 'true')
                } else {
                    removeCookie('is_classic_signup')
                }

                if (initialLeadParams.converted) {
                    console.info('Lead already converted, clearing pdc_lead_session_token cookie and navigating to pricing page...')
                    removeCookie('pdc_lead_session_token')
                    alert('Your sign-up session has been completed, you will now be redirected to the pricing page where you may sign-up again if you wish')
                    API.redirectToPricingPage()
                    return
                }

                GTM.signUpStart(initialLeadParams)

                const countryCode = initialLeadParams.billing_country_code || 'US'
                const country = countryCode ? countries.find(c => c.alpha2 === countryCode)?.name : ''

                setValidatedZipCode(initialLeadParams.billing_zip_code || '')

                // Initialize lead params
                setLeadParams({
                    ...initialLeadParams,
                    payment_period: typeof initialLeadParams.payment_period === 'string' ? parseInt(initialLeadParams.payment_period) : initialLeadParams.payment_period, /* eslint-disable-line @typescript-eslint/naming-convention */
                    personal_phone_number: initialLeadParams.personal_phone_number ? formatPhoneNumber(initialLeadParams.personal_phone_number) : '', /* eslint-disable-line @typescript-eslint/naming-convention */
                    country,
                    billing_country_code: countryCode, // eslint-disable-line @typescript-eslint/naming-convention
                    password: '' // never storred or retrieved, but a field that will need to be filled out and submitted
                })
            }
        }, error => {
            setLoadingError(error)
        })
    }

    useEffect(() => {
        initLead()
    }, [])

    const saveLeadParams = async (fields: string[], mfaReturnURL: string | null = null) => {
        setSavingLead(true)

        const fieldsToSave = {}
        fields.forEach(field => {
            fieldsToSave[field] = leadParams[field]
        })

        let response = null
        try {
            await validateLeadParams(fieldsToSave)
            response = await API.saveLead(fieldsToSave, mfaReturnURL)
        } catch (error) {
            setSavingLead(false)
            throw error
        }

        if (mfaReturnURL) {
            skipMFA.current = response.data.skip_mfa
            if (skipMFA.current) {
                GTM.mfaExempt(response?.data.details)
            }
            mfaSession.current = response.data.mfa_session
        }

        setSavingLead(false)
    }

    const performMFASteps = (): boolean => {
        const mfaAppURL = mfaSession.current?.mfa_app_url

        if (skipMFA.current) {
            console.info('MFA: user is exempt from MFA')
            return true
        } else if (mfaAppURL) {
            console.info(`MFA: sending user to MFA app: ${mfaAppURL}`)
            setRedirectingToMFA(true)
            window.location = mfaAppURL
            return false
        } else {
            throw new Error('performMFASteps(): Expected to either have received an MFA App URL or the skip_mfa flag')
        }
    }

    const sendGTMBussinessInfo = debounce(() => {
        if (leadParams) {
            GTM.businessInfoChanged(leadParams)

            if (isEmailValid(leadParams.email)) {
                GTM.emailProvided(leadParams.email)
            }
        }
    }, 2000)

    useEffect(() => {
        sendGTMBussinessInfo()
    }, [leadParams])

    const checkZipCode = async () : Promise<boolean> => {
        setCheckingZip(true)
        let isValid = false
        try {
            await API.checkZipCode(leadParams.billing_zip_code)
            isValid = true
        } catch (error) {
            console.info('zip code validation error: ', error)
        }
        setIsZipValid(isValid)
        setCheckingZip(false)
        return isValid
    }

    // Debounce this so rapid changes in address info, don't overly aggressivley trigger
    // requests to save lead info and re-fetch the order summary
    const handleAddressChange = debounce(async (zipCode: string) => {
        const isZipValid = await checkZipCode()

        try {
            await API.saveLead({
                street: leadParams?.street,
                city: leadParams?.city,
                state: leadParams?.state,
                country: leadParams?.country,
                billing_zip_code: leadParams?.billing_zip_code, // eslint-disable-line @typescript-eslint/naming-convention
                billing_country_code: leadParams?.billing_country_code // eslint-disable-line @typescript-eslint/naming-convention
            })
        } catch (error) {
            console.error('failed to save lead, error: ', error)
            return
        }

        if (isZipValid) {
            // Once a valid zip code has been saved, we update this state which will trigger re-fetching
            // the order summary with updated taxes & fees
            setValidatedZipCode(zipCode)
        }
    }, 200)

    useEffect(() => {
        if (leadParams) {
            const zipCode = leadParams.billing_zip_code || ''
            const requiredLength = leadParams.billing_country_code === 'CA' ? 7 : 5
            if (leadParams.billing_country_code && leadParams.state && zipCode.length >= requiredLength) {
                handleAddressChange(zipCode)
            }
        }
    }, [leadParams?.billing_country_code, leadParams?.state, leadParams?.billing_zip_code])

    const addToCart = (cartItem: CartItem) => {
        if (leadParams) {
            if (!leadParams.cart.some(item => item.reservation_token === cartItem.reservation_token)) {
                const updatedCart = leadParams.cart.concat([cartItem])
                setLeadParams({ ...leadParams, cart: updatedCart })
            } else {
                console.log('LeadProvider: this number is already reserved, not adding to cart')
            }
        } else {
            throw new Error('lead not initialized, can not add to cart')
        }
    }

    const clearCart = () => {
        setLeadParams({ ...leadParams, cart: [] })
    }

    const setCouponCode = (code: string) => {
        setLeadParams({ ...leadParams, coupon_code: code }) // eslint-disable-line @typescript-eslint/naming-convention
    }

    const setPaymentPeriod = (paymentPeriod: number) => {
        setLeadParams({ ...leadParams, payment_period: parseInt(paymentPeriod) }) // eslint-disable-line @typescript-eslint/naming-convention
    }

    return loadingError
        ? (
            <Alert
                data-testid="error-alert"
                showIcon={true}
                color={AlertColor.ERROR}
                content={<>An error occured while trying to load this page, <a onClick={initLead}>Click here to try again</a></>}
            />
        )
        : leadParams && !redirectingToMFA
            ? (
                <LeadContext.Provider
                    value={{
                        leadParams,
                        setLeadParams,
                        saveLeadParams,
                        performMFASteps,
                        savingLead,
                        validatedZipCode,
                        checkingZip,
                        isZipValid,
                        addToCart,
                        clearCart,
                        setCouponCode,
                        setPaymentPeriod
                    }}
                >
                    {children}
                </LeadContext.Provider>
            )
            : (
                <span className={classes.spinnerWrapper}>
                    <Spinner size="big" data-testid="loading-spinner" />
                </span>
            )
}

export default LeadProvider
