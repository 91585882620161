import React from 'react'
import { CartItem, LeadParams } from './types'

/**
 * Value to be passed/received using the LeadContext
 */
export interface LeadContextValue {
    leadParams: LeadParams;
    setLeadParams: (params: LeadParams) => void;
    saveLeadParams: (fields: string[], mfaReturnURL?: string) => void;
    savingLead: boolean;
    checkingZip: boolean;
    isZipValid: boolean;
    validatedZipCode: null | string;
    addToCart: (cartItem: CartItem) => void;
    clearCart: () => void;
    setCouponCode?: (couponCode: string) => void;
    setPaymentPeriod?: (paymentPeriod: number) => void;
}

/**
 * Context to provide access to lead data / params
 */
export default React.createContext<LeadContextValue>({
    leadParams: {},
    setLeadParams: () => {
        throw new Error('Component must be rendered inside a LeadProvider')
    },
    savingLead: false,
    checkingZip: false,
    isZipValid: true,
    validatedZipCode: null
})
